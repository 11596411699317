import images from "../pages/images";

export const ConvoData = [
  {
    id: 1,
    slug: "Rubber-Conveyor-Belt",
    name: "Rubber Conveyor Belt",
    img: images.homeprod1,
    sort_des:
      "he range of conveyor belts is generally installed in the conveyor system to transport different ",
    inarray1: {
      inname: "Rubber Conveyor Belt",
      des: "he range of conveyor belts is generally installed in the conveyor system to transport different goods, packages, cartons, food items and many other items from one place to another. In order to make certainty of our manufactured rubber conveyor belt, our quality controllers carefully and thoroughly inspect against various parameters. Due to its excellent load bearing facilities and high power, the range provided is extremely demanding in dealing with different materials, for example forging, food processing, crusher industry, construction lines, engineering industry, cement industry, sugar industry, etc. In addition, our customers can. Take advantage of this product from us at industrial leading rates and customized packaging.",
      spacification:
        "<p>General Widths: 500 mm to 2200 mm.</p> <br/> Carcass Variety Available: EP / NN. <br/> Common Belt Rating: 200 to 1800 kN/m. <br/> No. of Plies: 2 to 7 ply. <br/> Rubber Cover Thickness: 3 mm to 25 mm (1/16” to 1”) <br/> Edge: Cut/Moulded Edge. <br/> Splicing Method: Hot/ Cold/ Mechanical.",
      addinfo: [],
      application:
        "Cement works, iron and steel industry, Fly ashes and coal, Boiler ashes, chemical industries- Fertilisers, foundries, lime kilns, mining industries, stone crusher industry, salt works, public works, Glassworks, etc.",
    },
  },
  {
    id: 2,
    slug: "Plastic-Modular",
    name: "Plastic Modular",
    img: images.conveprod2,

    sort_des:
      "The modular belt conveyor is the straight version of this conveyor type. In combination with c",
    inarray1: {
      inname: "Rubber Conveyor Belt",

      des: "The modular belt conveyor is the straight version of this conveyor type. In combination with curves and vertical buckling , the track layout can be flexibly adapted to any existing ambient conditions and almost every route in three-dimensional space can be realized. Modular belt conveyors are extremely robust and can be used for almost any transport application. They are more resistant to wear than straps and can also be used where sharp-edged goods need to be transported or where a rough environment of use prevails. The conveyor system is due to various chain materials also food grade, suitable for high temperatures and chemically resistant.",
      spacification:
        "<p>Heavy duty construction</br>Drift free design</br>Easy to install</br>Most Power saving design, UNI M-QNB Blue, 8 mm Thickness,</br>Ammeraal Beltech ,Top Smooth, Poy-Proplene</p>",
      addinfo: [
        {
          id: 1,
          name: "Color",
          des: "White, Blue, Red, Green",
        },
      ],
      application:
        "Auto parts, Sorting, Separating Plastic, Metals, Inspection, Assembly lines, Manufacturing, Distribution, Warehouse",
    },
  },

  {
    id: 3,
    slug: "Cotton-Belt-Canvas-Belt",
    name: "Cotton Belt / Canvas Belt",
    img: images.conveprod3,

    sort_des:
      "We are bracketed with the top most Open Roll Type Cotton Conveyor Belt Manufacturers, Exporters",
    inarray1: {
      inname: "Rubber Conveyor Belt",

      des: "We are bracketed with the top most Open Roll Type Cotton Conveyor Belt Manufacturers, Exporters and Suppliers from Tamil Nadu, India. We offer an excellent range of Open Roll Type Cotton Conveyor Belt which is designed in compliance with the set industrial guidelines and standards. It is fabricated from best cotton imported yarn that is proved helpful in the cooling process. We make them available in various lengths and thickness to cater to the diverse industrial requirements. Buy them from us at unbeatable prices.",
      spacification: "<p>2 ply weaving <br/> 4 ply weaving</p>",
      addinfo: [
        {
          id: 1,
          name: "Color",
          des: "Raw White or Gray Color",
        },
      ],
      application: "Biscuit industry Tea industry Food Industry",
    },
  },
  {
    id: 4,
    slug: "Pvc-P.U Conveyor-Belt",
    name: "Pvc / P.U Conveyor Belt",
    img: images.conveprod4,

    sort_des:
      "We are offering a wide array of pu conveyor belt. We offer these belts in wide-range of sizes",
    inarray1: {
      inname: "Rubber Conveyor Belt",

      des: "We are offering a wide array of pu conveyor belt. We offer these belts in wide-range of sizes to provide the various requirements of our customers in an efficient manner. Offered belts are manufactured by the use of premium quality materials which we procure from the trustworthy vendors of the industry. In addition to this, these belts can be availed at market leading rates.",
      spacification: "",
      addinfo: [
        {
          id: 1,
          name: "Color",
          des: "White, Blue, Green, Black",
        },
      ],
      application: "Conveyor",
    },
  },
  {
    id: 5,
    slug: "Printing-Blanket",
    name: "Printing Blanket",
    img: images.conveprod5,

    sort_des:
      "Our product range comprises Conveyor and Process Belt, Industrial Power Transmission Belts, Mo",
    inarray1: {
      inname: "Rubber Conveyor Belt",

      des: "Our product range comprises Conveyor and Process Belt, Industrial Power Transmission Belts, Modular Conveyor Chains and Components, Industrial Pulleys and Drive Components, Damon Industrial Rollers. Each of these products is made of advanced technologies and high-grade materials. They are procured from the authorized and leading manufacturers. And so, they are second to none in terms of quality and availed in bulk quantity by several industries. Customers appreciate our products for their high performance, durability, strong rust resistance, economical prices and low maintenance. Moreover, we offer our products in various technical specifications that suit the customer's exact needs.",
      spacification: "",
      addinfo: [
        {
          id: 1,
          name: "",
          des: "",
        },
      ],
      application: "",
    },
  },
];
